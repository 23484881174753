import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import copy from 'copy-to-clipboard';
import qrcode from 'qrcode-generator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import { getCryptoIcon } from "../../../common/getCryptoIcon";
import { constants } from '../../../common/constants';

const DepositModal = (props) => {
    const theme = useSelector((state) => state.common.theme);
    const api_url = useSelector((state) => state.config.api_url);
    const [copied, setCopied] = useState(false);
    const typeNumber = 4;
    const errorCorrectionLevel = 'L';
    const image_base_url = useSelector((state) => state.config.image_base_url);


    const [openCryptoOption, setOpenCryptoOption] = useState(false);
    const [preSelectedBlockchain, setPreSelectedBlockchain] = useState(props.blockchain);

    const copyIcon = '/homeb2c/copy_light.webp';

    const [data, setData] = useState([]);


    const [cryptoWallet, setCryptoWallet] = useState(data);
    const [selectedCrypto, setSelectedCrypto] = useState();


    useEffect(() => {
        fetchBalances();
    }, []);

    useEffect(() => {
        if (selectedCrypto !== '' && cryptoWallet && cryptoWallet.length > 0) {
            generateQRCode(selectedCrypto.address.key);
        }
    }, [selectedCrypto, cryptoWallet])


    const fetchBalances = async () => {
        apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/get-merchant-wallets', {
            merchantId: localStorage.getItem('merchantId'),
        },
            (res) => {
                if (res.length > 0 && res.length == 1) {
                    setPreSelectedBlockchain(res[0].blockchain);
                }
                setData(res)
                setCryptoWallet(res)
                if (res && res.length > 0) {
                    if (props.fromDepositCurrency && !["Litecoin", "Ripple"].includes(props.fromDepositCurrency)) {
                        res.map(crypto => {
                            if (crypto.blockchain === props.fromDepositCurrency && res.length !== 1) {
                                setSelectedCrypto(crypto)
                            } else if (res.length === 1) {
                                setSelectedCrypto(crypto)
                            }
                        })
                    } else {
                        setSelectedCrypto(res[0])
                    }
                }
            }, (err) => {
                console.log(err);
            })

    };

    const getSymbol = (symbol) => {
        switch (symbol) {
            case 'BTC':
                return 'Bitcoin (BTC) ';
            case 'ETH':
                return 'Ethereum (ETH) ';
            case 'USDC':
                return 'USD Coin (USDC) ';
            case 'USDT':
                return 'USDT (USDT)';
            case 'DAI':
                return 'DAI (DAI)';
            case constants?.TRX_CRYPTO_ID:
                return 'Tron (TRX)';
            case constants?.TRC20_USDT_CRYPTO_ID:
                return 'Tron (TRC20-USDT)'
            default:
                return 'Bitcoin (BTC) ';
        }
    };

    const handleClose = () => {
        props.onClose();
    };

    const handleCrypto = () => {
        setOpenCryptoOption(!openCryptoOption);
    };

    const handleSelectCryptoOptions = (ele) => {
        setSelectedCrypto(ele);
        setOpenCryptoOption(false);
    };

    const generateQRCode = (qrCodeText) => {
        try {
            var qr = qrcode(typeNumber, errorCorrectionLevel);
            qr.addData(qrCodeText);
            qr.make();
            document.getElementById('depsitQRCode').innerHTML = qr.createImgTag();
        } catch (e) {
            console.log(e);
        }
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };


    return (
        <div >
            <Dialog onClose={props.onClose} open={props.open} className="b2c-signup-model deposit_model_mobile">
                <div className="enable-authenticator-model" style={{ width: '461px', minHeight: '575px' }}>
                    <Card style={{ width: '461px', minHeight: '575px' }} className="card-bg">
                        <div className="enable-authenticator-header">
                            <div></div>
                            <div data-e2e="receive-crypto-window">{`Deposit`}</div>
                            <div className="signup-close-icon" data-e2e="close-icon">
                                <ClearIcon onClick={() => handleClose()} className="" />
                            </div>
                        </div>
                        {cryptoWallet && cryptoWallet.length > 0 && <div className="crypto_heading">{`Cryptocurrency`}</div>}
                        {cryptoWallet && cryptoWallet.length > 0 ? (
                            <div className="enable-authenticator-content">
                                <div
                                    className={openCryptoOption ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
                                    onClick={() => handleCrypto()}
                                >
                                    <div className="verify-onboard-sub-heading">
                                        {selectedCrypto && selectedCrypto !== '' ? (
                                            <div>
                                                {' '}
                                                <img src={`${image_base_url}${getCryptoIcon(selectedCrypto.cryptoId.symbol)}`} className='currencyImg' data-e2e="copy-icon" alt="bitcoin" height={30} width={30} />{' '}
                                                <span className="ml-10" data-e2e-drop="receive-drop-down" data-e2e={selectedCrypto.blockchain}>{selectedCrypto.blockchain}</span>
                                            </div>
                                        ) : (
                                            cryptoWallet &&
                                            cryptoWallet.length > 0 && (
                                                <div>
                                                    <img
                                                        src={`${image_base_url}${getCryptoIcon(cryptoWallet.filter((obj => {
                                                            return obj.blockchain === preSelectedBlockchain
                                                        }))[0].name)}`}
                                                        className='currencyImg' alt="bitcoin" height={30} width={30} />{' '}
                                                    <span className="ml-10">{cryptoWallet.filter((obj => {
                                                        return obj.blockchain === preSelectedBlockchain
                                                    }))[0].blockchain}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>{openCryptoOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
                                </div>
                                {openCryptoOption && (
                                    <div className="kyc-options-container verify-onboard-sub-heading">
                                        {cryptoWallet &&
                                            cryptoWallet.length > 0 &&
                                            cryptoWallet.map((ele, index) => {
                                                return (
                                                    <div className="kyc-options cursor-pointer">
                                                        <div key={index} onClick={() => handleSelectCryptoOptions(ele)}>
                                                            <div>
                                                                {' '}
                                                                <img src={`${image_base_url}${getCryptoIcon(ele.cryptoId.symbol)}`} className='currencyImg' alt="bitcoin" height={30} width={30} />{' '}
                                                                <span className="ml-10">{ele.blockchain}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                                <div className="authenticator-scanner">
                                    <style>{`\
                                        .cryptoWalletQRCode > img { width: 170px; height: 170px; margin:auto }\
                                    `}</style>
                                    <div id="depsitQRCode" data-e2e="QRCode" className="cryptoWalletQRCode deposit_qrCode" />
                                </div>

                                <div className="content_container">
                                    <p>
                                        <div className="signup-input-label font-bold mt-20 receive-crypto-text-header">
                                            Only send{' '}
                                            {selectedCrypto && selectedCrypto !== ''
                                                ? getSymbol(selectedCrypto.cryptoId.symbol) : ''}{' '}
                                            &nbsp;to this address
                                        </div>
                                    </p>
                                    <div className="authenticator-text mb-30 receive-crypto-text">
                                        Sending any other asset, other than{' '}
                                        {selectedCrypto && selectedCrypto !== ''
                                            ? getSymbol(selectedCrypto.cryptoId.symbol) : ''}{' '}
                                        , will result in permanent loss.
                                    </div>
                                </div>

                                <div className={`kyc-select-options kyc-select cursor-pointer mb-10 copy-text ${copied ? 'active' : ''}`}>
                                    <div className="verify-onboard-sub-heading">
                                        {selectedCrypto && selectedCrypto !== ''
                                            ? selectedCrypto &&
                                            selectedCrypto.address.key &&
                                            selectedCrypto.address.key.substring(0, 21) +
                                            '....'
                                            : cryptoWallet[0] &&
                                            cryptoWallet[0].address.key &&
                                            cryptoWallet[0].address.key.substring(0, 21) +
                                            '....'
                                        }
                                    </div>
                                    <div
                                        onClick={() => {
                                            copy(selectedCrypto && selectedCrypto !== '' ? selectedCrypto.address.key : cryptoWallet[0].address.key);
                                            handleCopy();
                                        }}
                                    >
                                        <img src={`${image_base_url}${copyIcon}`} alt="bitcoin" height={20} data-e2e="copy-icon" width={17} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="crypto_heading">No wallets available </div>
                        )}
                    </Card>
                </div>
            </Dialog>
        </div>
    )
}

export default DepositModal