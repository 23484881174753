import React from 'react';
import { Card, Dialog } from '@mui/material';
import { connect } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import CreditCardPayment from './CreditCardPayment';
import * as actions from '../../../../Redux/actions/index';
import SelectCreditCard from './SelectCreditCard';
const darkToggle = "/common/crytoicons/dark_toggle_icon2x.png";
const lightToggle = "/common/crytoicons/light_toggle_icon2x.png";
import { constants } from '../../../../common/constants';
class CreditCardModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lightFlag: false,
            transactionId: null,
            loadingModalFlag:false,
            deleteCard: null,
            addNewCard: false,
            saveCardList: [],
            selectedCard: null,
            emptyCardList: false,
        }
    }

    handleToggle = (paramMode) => {
        if (paramMode) {
            let res = paramMode == 'light' ? true : false
            sessionStorage.setItem("lightFlag", res);
            this.setState({ lightFlag: res })
            return
        }
        sessionStorage.setItem("lightFlag", !this.state.lightFlag);
        this.setState({ lightFlag: !this.state.lightFlag })
    }

    deleteCardFormDB = async (transaction, removeCardId, transactionIdOfCardToken) => {
        if (transaction) {
            let integrationTypeForDeletedCard = null, routingIdForDeletedCard = null, acquirerNameForDeletedCard = null;
            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/getIntegrationTypeForTransaction', { transactionId: transactionIdOfCardToken }, async (data) => {
                if (data) {
                    integrationTypeForDeletedCard = data.integrationTypeForDeletedCard
                    routingIdForDeletedCard = data.routingId
                    acquirerNameForDeletedCard = data.acquireName
                }
                const query = {
                    b2cUserId: transaction.b2cUserId,
                    removeCardId,
                };
                await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/delete-b2c-saved-credit-card', query, async (dataObj) => {
                    if (dataObj) {
                        if (!dataObj.removeCardId) {
                            alert("Card remove failed!");
                        } else {
                            const tokenizeData = {
                                ...query,
                                cardToken: dataObj.cardToken,
                                routingId: routingIdForDeletedCard,
                                acquirerName: acquirerNameForDeletedCard,
                            }
                            switch (integrationTypeForDeletedCard) {
                                case constants.GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE:
                                    await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/golden-gateway-card-tokenize', { ...tokenizeData, typeOfTokenization: "deleteCard" }, (res) => {
                                        if (res) {
                                            console.log("card token deactivation success")
                                        }
                                    }, (err) => {
                                        console.log("errrr", err)
                                    })
                                    break;
                                case constants.WORLD_CARD_INTEGRATION_TYPE:
                                    await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/worldCardDeleteToken',
                                        tokenizeData, (res) => {
                                            if (res) {
                                                console.log("card token deactivation success WorldCard")
                                            }
                                        }, (err) => {
                                            console.log("errrr", err)
                                        })
                                    break;
                                default:
                                    console.error("IntegrationType not found for the token!")
                                    break;
                            }
                        }
                    }
                }, (err) => {
                    console.error("errrr", err)
                })
            }, (err) => {
                console.error("errrr", err)
            })
        }
    }
    getFiatCurrencySymbol = (fiatCurrency) => {
        switch (fiatCurrency) {
            case "USD":
                return "$";
            case "GBP":
                return "£";
            case "EUR":
                return "€";
            case "RUB":
                return "р.";
            case "JPY":
                return "¥";
            default:
                return fiatCurrency
        }
    }
    componentDidMount = async () => {
        if(localStorage.getItem("transactionId") ){
            this.setState({ transactionId: localStorage.getItem("transactionId") })
        }
        let transaction = await this.getTransaction()
        if (transaction) {
            await this.init(transaction)
        }
    }

    checkCoinsSpirit = () => {
        return (window && window.location && window.location.hostname &&
            window.location.hostname.includes(this.props.coinSpiritHostName)) || false;
    };

    getTransaction = () => {

        return new Promise(async (resolve, reject) => {
            const transactionId = localStorage.getItem("transactionId");
            const b2cUserId = localStorage.getItem("b2cUserId");
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-b2c-transaction-details', {
                transactionId,
                b2cUserId,
            }, async (data) => {
                if (data) {
                    this.props.setTransaction(data);
                    resolve(data)
                }
            }, (err) => {
                reject(err);
                console.log("err:", err)
            })
        })
    }

    init =  async (transaction) => {
        if (transaction) {
            const query = {
                b2cUserId: transaction.b2cUserId,
            };
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-b2c-saved-credit-card-list', query, async (data) => {
                if (data) {
                    if (data.savedCardList && data.savedCardList.length > 0) {
                        this.setState({ 
                            saveCardList: data.savedCardList,
                            selectedCard: data.savedCardList[0], 
                            addNewCard: false,
                         });
                    } else {
                        console.log("add card value change");
                        this.setState({ addNewCard: true, emptyCardList: true});
                    }
                }
            }, (err) => {
                console.error("errrr", err)
            })
        }
    }

    render() {
        return (
            <div>
                <Dialog fullWidth maxWidth={this.checkCoinsSpirit() ? "md" : "sm"} style={{zIndex: 1500}} onClose={this.props.onClose} open={this.props.open}>
                    <div className="enable-authenticator-model credit-card-modal">
                        <Card className="card-bg">
                            <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new credit_card_modal"}>
                                <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                                <div className="deposit_screen_wrap">
                                    <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"} id={this.checkCoinsSpirit() ? "creditCardPaymentSummaryWidthWalletHome" : ""}>
                                        {!this.state.loadingModalFlag && <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                                            <span className={`select-crypt-title deposit-credit-card-title ${this.state.lightFlag ? "text_color_black" : ""}`}>
                                                {this.checkCoinsSpirit() ? "" : this.state.deleteCard ? "Remove card" : "Buy Crypto"}
                                            </span>
                                            <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                                        </div>}
                                        {(this.checkCoinsSpirit() || this.state.deleteCard || this.state.loadingModalFlag) ? null : <div className={`${this.state.lightFlag ? "text_color_black" : "text_color_white"}`} style={{ marginLeft: "30px" }}>Amount: <b>{this.getFiatCurrencySymbol(this.props.fiatCurrency ? this.props.fiatCurrency : "USD")}{this.props.depositAmount ? this.props.depositAmount : 0} {this.props.fiatCurrency ? this.props.fiatCurrency : "USD"}</b></div>}
                                        {this.state.addNewCard ?
                                            <CreditCardPayment
                                                transactionId={this.state.transactionId}
                                                handleLoadingModal={() => this.setState({ loadingModalFlag: true })}
                                                fiatCurrency={this.props.fiatCurrency ? this.props.fiatCurrency : "USD"}
                                                fiatAmount={this.props.depositAmount ? this.props.depositAmount : 0}
                                                lightFlag={this.state.lightFlag} image_base_url={this.props.image_base_url}
                                                cryptoId={this.props.cryptoId}
                                                cryptoAmount={this.props.cryptoAmount}
                                                cryptoIcon={this.props.cryptoIcon}
                                                fiatCurrencySymbol = {this.getFiatCurrencySymbol(this.props.fiatCurrency ? this.props.fiatCurrency : "USD")}
                                                />
                                            : <SelectCreditCard
                                                transactionId={this.state.transactionId}
                                                fiatCurrency={this.props.fiatCurrency ? this.props.fiatCurrency : "USD"}
                                                fiatAmount={this.props.depositAmount ? this.props.depositAmount : 0}
                                                saveCardList={this.state.saveCardList}
                                                selectedCard={this.state.selectedCard}
                                                isAddNewCard={(value) => {
                                                    this.setState({ addNewCard: value })
                                                }}
                                                handleLoadingModal={() => this.setState({ loadingModalFlag: true })}
                                                setEmptyCardListFlag={(value) => {
                                                    this.setState({ emptyCardList: value })
                                                }}
                                                lightFlag={this.state.lightFlag}
                                                setDeleteCard={(card) => {
                                                    this.setState({ deleteCard: card })
                                                }}
                                                deleteCard={this.state.deleteCard}
                                                deleteCardFormDB={(removeCardId, transactionIdOfCardToken) => { this.deleteCardFormDB(this.props.transaction, removeCardId, transactionIdOfCardToken) }}
                                                image_base_url={this.props.image_base_url} 
                                                cryptoId={this.props.cryptoId}
                                                cryptoAmount={this.props.cryptoAmount}
                                                cryptoIcon={this.props.cryptoIcon}
                                                fiatCurrencySymbol = {this.getFiatCurrencySymbol(this.props.fiatCurrency ? this.props.fiatCurrency : "USD")}
                                                />}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        payment_api_url: state.config.payment_api_url,
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
        loading: state.common.loading,
        token: state.common.token,
        transaction: state.deposit.transaction,
        coinSpiritHostName: state.config.COIN_SPIRIT_HOST_NAME
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTransaction: (value) => dispatch(actions.setTransaction(value)),
        setToken: (data) => dispatch(actions.setToken(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardModal);
