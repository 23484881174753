import React from 'react';
import Deposit from "./components/Pages/Deposit/Deposit.js";
import DepositTracking from './components/Pages/Deposit/DepositTracking';
import DepositStatus from './components/Pages/Deposit/DepositStatus';
import MyAccountDetails from './components/Pages/MyAccount/MyAccountDetails';
import ChangePassword from './components/Pages/ChangePassword/ChangePassword';
import APIGuide from './components/Pages/MerchantTools/APIGuide';
import Authentication from './components/Pages/MerchantTools/Authentication';
import CreatePayment from './components/Pages/MerchantTools/CreatePayment';
import CreatePayout from './components/Pages/MerchantTools/CreatePayout';
import GetTransactions from './components/Pages/MerchantTools/GetTransactions';
import Webhooks from './components/Pages/MerchantTools/Webhooks';
import ErrorHandling from './components/Pages/MerchantTools/ErrorHandling';
import B2CHome from './components/Pages/B2C/B2CHome';
import NewPassword from './components/Pages/ForgotPassword/NewPassword';
import HomeMerchant from './components/Pages/Home/HomeMerchant';
import PaysuHomeMerchant from './components/Pages/Paysu/PaysuHomeMerchant';
import WalletsDetail from './components/Pages/B2C/UserWalletsHome/WalletDetail'
import B2CSettings from './components/Layouts/B2CSettings/B2CSettings';
import B2cTransactionTable from './components/Pages/B2C/UserWalletsHome/b2cTransactionTable';
import B2CNewPassword from './components/Pages/NewPassword';
import AddAdress from './components/Pages/B2C/AddAddress/AddAddress';
import DepositSuccessful from './components/Pages/Deposit/DepositSuccessful';
import FaqHome from './components/Pages/Faqs/faqHome';
import FaqDetails from './components/Pages/Faqs/faqDetails';
import PayLoading from './components/Pages/Deposit/PayLoading';
import CardResponseLoading from './components/Pages/Deposit/CardResponseLoading';
import DepositSuccessForCreditCard from './components/Pages/Deposit/DepositSuccessForCreditCard';
import DepositFailedForCreditCard from './components/Pages/Deposit/DepositFailedForCreditCard';
import TermsOfUse from './components/Pages/B2C/TermsOfUse/TermsOfUse';
import RefundFailedForCreditCard from './components/Pages/Deposit/RefundFailedForCreditCard';
import RefundSuccessForCreditCard from './components/Pages/Deposit/RefundSuccessForCreditCard';
import TransactionStatusCheckLoading from './components/Pages/Deposit/TransactionStatusCheckLoading';
import B2CHomePaysu from './components/Pages/Paysu/B2CHomePaysu';
import CoinSpiritPrivacyPolicy from './components/Pages/B2C/CoinspiritPrivacyPolicy/CoinSpiritPrivacyPolicy';
import CookiesPolicy from './components/Pages/B2C/CookiesPolicy/CookiesPolicy';
import KalonpayHomeMerchant from './components/Pages/kalonpay/KalonpayHomeMerchant';
import KalonpayPaymentForm from './components/Pages/kalonpay/payments/KalonpayPaymentForm';
import FourZeroFour from './common/404';
import TruevoCallback from './components/Pages/Deposit/TruevoCallback.js';



import KalonpaySuccessScreen from './components/Pages/kalonpay/payments/KalonpaySuccessScreen';
import KalonpayFailedSecreen from './components/Pages/kalonpay/payments/KalonpayFailedSecreen';
import CoinSwapCentrePrivacyPolicy from './components/Pages/B2C/CoinSwapCentrePrivacyPolicy/CoinSwapCentrePrivacyPolicy.js';
import B2CLogin from './components/Pages/B2CLogin/B2CLogin.js';
import B2CSignUp from './components/Pages/B2CSignUp/B2CSignUp.js';
import { constants } from './common/constants.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { createBrowserHistory } from 'history';
import LoadingAndRedirection from './common/LoadingAndRedirection.js';
import LocationWrapComponent from './components/Pages/Deposit/LocationWrapComponent.js';
import Header from './components/Layouts/HeaderAndFooter/Header.js';
const history = createBrowserHistory();

const RoutesList = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route
          exact
          path={constants.B2C_LOGIN_PATH}
          element={<B2CLogin />}
        />
         <Route
          exact
          path={constants.B2C_SIGNUP_PATH}
          element={<B2CSignUp />}
        />
         <Route
          exact
          path="/paysu-home-b2c"
          element={<B2CHomePaysu />}
        />
         <Route
          exact
          path="/deposit"
          element={<Deposit />}
        />
         <Route
          exact
          path="/cryptoToDeposit"
          element={<LocationWrapComponent componentName="SelectCryptoToDeposit"/>}
        />
         <Route
          exact
          path="/cryptopayWalletToDeposit"
          element={<LocationWrapComponent componentName="SelectCryptopayWalletToDeposit" />}
        />
         <Route
          exact
          path="/depositSuccessful"
          element={<DepositSuccessful />}
        />
         <Route
          exact
          path="/creditCard/depositSuccessful"
          element={<DepositSuccessForCreditCard />}
        />
         <Route
          exact
          path="/creditCard/depositFailed"
          element={<DepositFailedForCreditCard />}
        />
         <Route
          exact
          path="/kalonpay-payment/depositSuccess"
          element={<KalonpaySuccessScreen />}
        />
         <Route
          exact
          path="/kalonpay-payment/depositFailed"
          element={<KalonpayFailedSecreen />}
        />
         <Route
          exact
          path="/depositStatus"
          element={<DepositStatus />}
        />
         <Route
          exact
          path="/depositTracking"
          element={<DepositTracking />}
        />
         <Route
          exact
          path="/quickDeposit"
          element={<LocationWrapComponent componentName="SelectAmountToDeposit" />}
        />
         <Route
          exact
          path="/myAccount/:myAccountTab"
          element={<LocationWrapComponent componentName="MyAccount" />}
          />
         <Route
          exact
          path="/myAccount/:myAccountTab/:transactionId"
          element={<LocationWrapComponent componentName="MyAccount" />}
        />
         <Route
          exact
          path="/myAccountDetails"
          element={<MyAccountDetails />}
        />
         <Route
          exact
          path="/ChangePassword"
          element={<ChangePassword />}
        />
         <Route
          exact
          path="/forgotPassword/:token"
          element={<NewPassword />}
        />
         <Route
          exact
          path="/b2cUserForgotPassword/:token"
          element={<B2CNewPassword />}
        />
         <Route
          exact
          path="/loginPopup"
          element={<Header />}
        />
         <Route
          exact
          path="/merchantTools/APIGuide"
          element={<APIGuide />}
        />
         <Route
          exact
          path="/merchantTools/Authentication"
          element={<Authentication />}
        />
         <Route
          exact
          path="/merchantTools/CreatePayment"
          element={<CreatePayment />}
        />
         <Route
          exact
          path="/merchantTools/CreatePayout"
          element={<CreatePayout />}
        />
         <Route
          exact
          path="/merchantTools/GetTransactions"
          element={<GetTransactions />}
        />
         <Route
          exact
          path="/merchantTools/Webhooks"
          element={<Webhooks />}
        />
         <Route
          exact
          path="/merchantTools/ErrorHandling"
          element={<ErrorHandling />}
        />
         <Route
          exact
          path="/faq-home"
          element={<FaqHome />}
        />
         <Route
          exact
          path="/home-merchant"
          element={<HomeMerchant />}
        />
         <Route
          exact
          path="/paysu-home-merchant"
          element={<PaysuHomeMerchant />}
        />
         <Route
          exact
          path="/terms-of-use"
          element={<TermsOfUse />}
        />
         <Route
          exact
          path="/coinsspirit-privacy-policy"
          element={<CoinSpiritPrivacyPolicy />}
        />
         <Route
          exact
          path="/cookies-policy"
          element={<CookiesPolicy />}
        />
         <Route
          exact
          path="/faq-details"
          element={<FaqDetails />}
        />
         <Route
          exact
          path="/"
          element={<LoadingAndRedirection />}
        />
         <Route
          exact
          path="/home-b2c"
          element={<B2CHome />}
        />
         <Route
          exact
          path="/faq-b2c-home"
          element={<FaqHome />}
        />
         <Route
          exact
          path="/user-wallet-home"
          element={<WalletsDetail />}
        />
         <Route
          exact
          path="/user-settings-b2c"
          element={<B2CSettings />}
        />
         <Route
          exact
          path="/user-transaction-history-b2c"
          element={<B2cTransactionTable />}
        />
         <Route
          exact
          path="/add-address"
          element={<AddAdress />}
        />
         <Route
          exact
          path="/refundDeposit"
          element={<LocationWrapComponent componentName="RefundDeposit" />}
        />
         <Route
          exact
          path="/payLoading"
          element={<PayLoading />}
        />
         <Route
          exact
          path="/cardResponseLoading"
          element={<CardResponseLoading />}
        />
         <Route
          exact
          path="/SecurePayment"
          element={<LocationWrapComponent componentName="MerchantPayloading" />}
        />
         <Route
          exact
          path="/checkCardPayTransactionStatus"
          element={<TransactionStatusCheckLoading />}
        />
         <Route
          exact
          path="/truevoCallback"
          element={<TruevoCallback />}
        />
         <Route
          exact
          path="/kalonpay-home-merchant"
          element={<KalonpayHomeMerchant />}
        />
         <Route
          exact
          path="/kalonpay-payment"
          element={<KalonpayPaymentForm />}
        />
         <Route
          exact
          path="/coinsswapcenter-privacy-policy"
          element={<CoinSwapCentrePrivacyPolicy />}
        />
         <Route
          exact
          path="/external-privacy-policy-notice"
          element={<CoinSwapCentrePrivacyPolicy />}
        />
         <Route
          exact
          path="/creditCard/refundFailed"
          element={<RefundFailedForCreditCard />}
        />
         <Route
          exact
          path="/creditCard/refundSuccessful"
          element={<RefundSuccessForCreditCard />}
        />
         <Route
          path="*"
          element={<FourZeroFour />}
        />
      </Routes>
    </Router>
  )
}

export default RoutesList
