import DeleteIcon from '@mui/icons-material/Delete';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Table } from 'reactstrap';
import { getCryptoIcon } from "../../../../common/getCryptoIcon";
const AddAddressTable = (props) => {
  return (
    <div className="BalanceTableMain address-table">
      
        <div>
          <MediaQuery minWidth={481}>
            <Table responsive>
              <tbody className="BalanceTable_BODY">
                <tr style={{ height: '50px' }}>
                  <th className="BalanceTable_heading" width="18%">
                    ASSET{' '}
                  </th>
                  <th className="BalanceTable_heading" width="18%">
                     NICKNAME
                  </th>
                  <th className="BalanceTable_heading" width="12%">
                    ADDRESS
                  </th>
                  <th className="BalanceTable_heading" width="14%">
                    DATE ADDED
                  </th>
                  <th  className="BalanceTable_heading" width="9%">

                  </th>
                  <th  className="BalanceTable_heading" width="10%">

                  </th>
                 
                </tr>
                {props.data.map((data,index)=>{
                  return <SingleAddressRow 
                  data={data} 
                  key={index} 
                  setOpenDeleteModal={props.setOpenDeleteModal} 
                  openEditModalAndData={props.openEditModalAndData}
                  setOpenEditModalAndData={props.setOpenEditModalAndData}
                  openDeleteModal={props.openDeleteModal}
                  />
                })}
                
              </tbody>
            </Table>
          </MediaQuery>
         
     </div>
    </div>
  );
};

export default AddAddressTable;



const SingleAddressRow = (props) => {
  const [copied, setCopied] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);

function shortenAddress(word){
  if(word.length>40){
    return word.slice(0,30)+"..."+word.substr(word.length - 5);
  }else{
    return word
  }
}

function parseDate(date){
  let res= new Date(date).toDateString().split(' ')
  return res.slice(1).join(' ')
}
  return (
    <tr className="Balance_TableRow">
      <td className="BalanceTable_item">
        <img className="USTImages" src={`${image_base_url}${getCryptoIcon(props.data.cryptoId ? props.data.cryptoId.cryptoId:null )}`} alt="bitcoin" />
        <span>
          {' '}
          {props.data.cryptoId ? props.data.cryptoId.cryptoId : 'N/A'} / <span>{props.data.cryptoId ? props.data.cryptoId.name : "N/A"}</span>
        </span>
      </td>
      <td className="BalanceTable_item"> 
        <div>
          <div>{props.data.nickName}</div>
        </div>
      </td>
     
      <td className="BalanceTable_item">
        {' '}
        <div className='address-name-layout '>
          <div>{shortenAddress(props.data.address)}</div>
          {'  '}
          <div
              className={`copy__icon copy-button-margin copy-text ${copied ? 'active address' : ''}`}
              onClick={() => {
                copy(props.data.address);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 3000);
              }}
            >
              <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
            </div>
        </div>
      </td>

      <td className="BalanceTable_item">
        <div>
          <div>{parseDate(props.data.createdAt)}</div>
        </div>
      </td>
      <td className="BalanceTable_item">
        <div onClick={()=>{
          props.setOpenEditModalAndData({
            open:true,
            data:props.data
          })
        }}>
          <div className='edit-address-btn' data-e2e="edit-icon">Edit</div>
        </div>
      </td>
      <td className="BalanceTable_item">
        <div>
          <div>
           <DeleteIcon onClick={()=>props.setOpenDeleteModal({
             open:true,
             data:props.data
           })} fontSize='small' className="authenticator-close-icon delete-button-dynamic-color" data-e2e={`${props.data.nickName}-delete-icon`} />
          </div>
        </div>
      </td>
    </tr>
  );
};

