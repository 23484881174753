import React, { useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import copy from 'copy-to-clipboard';
const rejectIconBtn = "/common/crytoicons/tick_circle.svg"
const approveIconBtn = "/common/crytoicons/cross_circle.svg"
const approveIcon = "/common/crytoicons/approve.png"
const rejectIcon = "/common/crytoicons/deleteicon.png"
const closeImage = "/common/crytoicons/close.png"
const successIcon = "/common/crytoicons/success_changes.png"
const deleteIcon = "/common/crytoicons/deleteicon.png"

const allowedStatusList = [
    "DepositFlow:Launched",
    "DepositFlow:AwaitingDeposit",
    "DepositMonitor:MonitoringMemPool",
    "DepositMonitor:TimedOutMonitoringMemPool",
    "DepositMonitor:DepositReceivedToMemPool",
    "DepositMonitor:DepositConfirmed",
    "DepositMonitor:DepositPendingManualApproval",
    "DepositMonitor:DepositManualApproved",
    "DepositMonitor:DepositManualRejected",
    "DepositMonitor:DepositCompleted",
    "DepositMonitor:DepositManuallyCompleted",
    "WithdrawalFlow:CreatePayOut",
    "WithdrawalFlow:PendingApproval",
    "WithdrawalFlow:ApprovedForProcessing",
    "WithdrawalFlow:Rejected",
    "WalletManagementService:WithdrawalRejectionCompleted",
    "WalletManagementService:WithdrawalCompleted",
]

const TransactionDetails = (props) => {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const [showApprovalModal, setApprovalModalFlag] = useState(false);
    const [showRejectModal, setRejectModalFlag] = useState(false);
    const [msgExpanded, toggleMsgExpanded] = useState(false);
    const [approveComment, setApproveComment] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const [rejectComment, setRejectComment] = useState("");
    const userPermission = JSON.parse(localStorage.getItem("viewPermissionOnly"))
    const [viewPermissionOnly, setViewPermissionOnly]=useState(userPermission) 
    const [showDropdown, toggleDropdown] = useState(false);

    const getFiatCurrencySymbol = (fiatCurrency) => {
        switch (fiatCurrency) {
            case "USD":
                return "$";
            case "GBP":
                return "£";
            case "EUR":
                return "€";
            case "RUB":
                return "р.";
            case "JPY":
                return "¥";
            default:
                return fiatCurrency
        }
    }
    const getStatusComponent = (status) => {
        switch (status) {
            case "DepositFlow:Launched":
            case "DepositFlow:AwaitingDeposit":
            case "DepositMonitor:MonitoringMemPool":
            case "WithdrawalFlow:CreatePayOut":
                return <div data-e2e={status} className="Tstatus_witheld">{status}</div>;
            case "CreatePayment":
                return <div data-e2e="pending" className="Tstatus_witheld">PENDING</div>;

            case "DepositMonitor:DepositManuallyCompleted":
            case "DepositMonitor:DepositCompleted":
            case "WalletManagementService:WithdrawalCompleted":
            case "MerchantWallet:BalanceUpdated":
            case "WalletManagementService:TransferCompleted":
            case "DepositFlowViaWallet:DepositCompleted":
                return <div data-e2e="success" className="Tstatus_success">{status}</div>;
                // return <div data-e2e="success" className="Tstatus_success">SUCCESS</div>;

            case "DepositMonitor:DepositManualRejected":    
            case "WithdrawalFlow:Rejected":    
                return <div className="Tstatus_failed">{status}</div>;
            case "DepositMonitor:DepositRejected":    
            case "WalletManagementService:WithdrawalRejectionCompleted":    
                return <div className="Tstatus_failed">REJECTED</div>;

            case "DepositMonitor:TimedOutMonitoringMemPool": 
            case "DepositMonitor:DepositPendingManualApproval":
            case "WithdrawalFlow:PendingApproval":
                return <div data-e2e="withHeld" className="Tstatus_witheld">{status}</div>;
                // return <div data-e2e="withHeld" className="Tstatus_witheld">REQUIRE APPROVAL</div>;

            case "DepositMonitor:DepositReceivedToMemPool":
            case "DepositMonitor:DepositConfirmed":   
            case "DepositMonitor:DepositManualApproved":
            case "WithdrawalFlow:ApprovedForProcessing":
                return <div data-e2e={status} className="Tstatus_pending">{status}</div>;
            default:
                return <div data-e2e="processing" className="Tstatus_pending">PROCESSING</div>;
        }
    }

    const data = props.transactionDtls[0]
    const transactionDtls = {
        "Type":data.type && (data.type.charAt(0).toUpperCase() +data.type.slice(1)) ||'N/A', 
        "Status": data.status ? data.status.code : "N/A",
        "Status Message": data.status && data.status.message && data.status.message !== "" ? data.status.message : "",
        "User ID": data.merchantUserId,
        // "From Address" shown only in "deposits".
        ...(data && data.type === "deposit" && 
        {"From Address": data.fromWallet ? data.fromWallet.address:"-"}),
        "Date & Time":data.createdDate ? moment(data.createdDate).format("DD/MM/YYYY - HH:mm") : "N/A",             
         // "To Address" shown only in "withdrawal".
        ...(data && data.type === "withdrawal" && 
        {"To Address": (data.withdrawalWalletAddress && data.withdrawalWalletAddress) || 'N/A'}),
        "Crypto Value": data.selected ? `${data.type && data.type.toLowerCase() === "withdrawal" ? "" : (data.selected.amount ? data.selected.amount : "-")} ${data.selected.cryptoId}` : "-",
        "Fiat Value": data.depositAmount && data.depositFiatCurrency ? `${getFiatCurrencySymbol(data.depositFiatCurrency)} ${data.depositAmount}` : "-",
        "Amount Sent":data && data.type === 'deposit' ? (data && data.selected && data.selected.actualAmountDeposited) ||'N/A'
                        : (data && data.selected && data.selected.actualAmountSent) || 'N/A' +'-' +(data.selected && data.selected.cryptoId) ||'N/A',
        // ...(data && data.type === "deposit" && 
        // {"Confirmed (Fiat)": "-"}),
        ...(data && data.depositAmoun && data.originalDepositAmount && data.depositAmount !== data.originalDepositAmount && {
            "Orginal Deposit Amount" :data && data.originalDepositAmount ? data.originalDepositAmount : "0",
        }),         
        "Currency": data.depositFiatCurrency ? getFiatCurrencySymbol(data.depositFiatCurrency) : "-",
        "FX Rate": data.selected && data.selected.exchangeRate ? `${data.selected.exchangeRate}` : "-",
        "Fee": data.transactionFee ? `${data.transactionFee}` : "-",
    }
    const eventsLog = data && data.status && data.status.history ? data.status.history : []
    const cards = [{ heading: 'Details', key: 'transactionDtls' }, { heading: 'Event Log', key: 'eventsLog' }]
    const activeTab1Card = [{ heading: 'Details', key: 'transactionDtls' }]
    const activeTab2Card = [{ heading: 'Event Log', key: 'eventsLog' }]

  const [copied, setCopied] = useState(false);
  const useStyles = styled(Tooltip)({
    arrow: {
      "&:before": {
        backgroundColor: '#497cf9',
      },
    },
    tooltip: {
      fontSize: "1em",
      backgroundColor: '#497cf9',
      borderRadius: '32px',
      padding: '4px 18px',
      marginLeft: '-35px',
      marginBottom: '8px !important'
    },
  });
  
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  const classes = useStyles;  
    return (
        <div className="TransactionDtls">
            <div className="TransactionDtls_heading-wrap">
                <div className="TransactionDtls_heading"><span className="TransactionDtls_heading_trasactionsBtn"
                    onClick={() => {
                        props.clearTransactionDtlsScreen();
                    }}
                    data-e2e="transfer"
                >Transfers &gt; </span>
                &nbsp;Transaction ID:&nbsp;{data.transactionId
                    ? 
                       <span>
                       <MediaQuery minWidth={481}>
                        {(data.transactionId.toString().substring(0, 24) + "..." + data.transactionId.toString().substring(data.transactionId.toString().length - 4, data.transactionId.toString().length)) }
                        &nbsp;<Tooltip open={copied} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="right" >
                              <span onClick={() => 
                              {copy(data.transactionId);
                                handleCopy();}}
                              ><i className="fa fa-clone copy__icon" data-e2e="copy" aria-hidden="true"></i>
                              </span>
                            </Tooltip>
                       </MediaQuery>
                        <MediaQuery maxWidth={480}>
                            {data.transactionId.toString().substring(0, 12)}
                           &nbsp; <Tooltip open={copied} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="bottom" >
                              <span  onClick={() => 
                              {copy(data && data.transactionId && data.transactionId);
                                handleCopy();}}
                              ><i className="fa fa-clone copy__icon" data-e2e="copy" aria-hidden="true"></i>
                              </span>
                            </Tooltip>
                            </MediaQuery>                        
                      </span>
                    
                    : "N/A"} </div>
                <MediaQuery minWidth={481}>{data.type && data.type.toLowerCase() === "withdrawal" && data.status && data.status.code === "WithdrawalFlow:PendingApproval" && <div className="ApprovalButtons_wrap">
                    <div hidden = { viewPermissionOnly } data-e2e="approval-btn" className="approvalButton" onClick = { viewPermissionOnly ? () => {} : () => { setApprovalModalFlag(true) }}>APPROVE</div>
                    <div hidden = { viewPermissionOnly } className="rejectButton" onClick = { viewPermissionOnly ? () => {} : () => { setRejectModalFlag(true) }} data-e2e="reject">REJECT</div>
                </div>}</MediaQuery>
                {props.showSuccess && <div className="successModal">
                    <div className="successModalWrap">
                        <img className="successModalIcon" alt="successIcon" src={`${image_base_url}${successIcon}`} />
                        <div className="successModalMsg">Transaction has been successfully approved</div>
                        <img className="closeIcon" alt="closeIcon" src={`${image_base_url}${closeImage}`}
                            onClick={() => { props.toggleShowSuccess(false) }}
                        />
                    </div>
                </div>}
                {props.showError && <div className="successModal">
                    <div className="successModalWrap">
                        <img className="successModalIcon" alt="errorIcon" src={`${image_base_url}${deleteIcon}`} />
                        <div className="successModalMsg">Transaction has been rejected</div>
                        <img className="closeIcon" alt="closeIcon" src={`${image_base_url}${closeImage}`}
                            onClick={() => { props.toggleShowError(false) }}
                        />
                    </div>
                </div>}
            </div>
            <MediaQuery maxWidth={480}>
                <div className="ControllerNav">
                    <div className="CrontrollerBtns">
                        <div onClick={() => { setActiveTab(1) }} className={`CrontrollerBtn ${activeTab === 1 ? 'CrontrollerBtnActive' : ''}`}>
                            Details
                        </div>
                        <div onClick={() => { setActiveTab(2) }} className={`CrontrollerBtn ${activeTab === 2 ? 'CrontrollerBtnActive' : ''}`}>
                            Event Log
                        </div>
                    </div>
                    {data.type && data.type.toLowerCase() === "withdrawal" && data.status && data.status.code === "WithdrawalFlow:PendingApproval" && <div className="ApprovalButtons_wrap">
                        <img hidden = { viewPermissionOnly } src={`${image_base_url}${rejectIconBtn}`} className="rejectIconBtn" onClick = { viewPermissionOnly ? () => {} : () => { setApprovalModalFlag(true) }} />
                        <img hidden = { viewPermissionOnly } src={`${image_base_url}${approveIconBtn}`} className="approveIconBtn" onClick = { viewPermissionOnly ? () => {} : () => { setRejectModalFlag(true) }} />
                    </div>}
                </div></MediaQuery>
            <MediaQuery maxWidth={480}><div className="TransactionDtls_container">
                {activeTab === 1 &&
                    activeTab1Card.map((obj, i) =>
                        <div key={i} className="TransactionDtls_cards">
                            <div className={`TransactionDtls_cards_NoScroll TransactionDtls_cards_Scroll`}>
                                {Object.keys(transactionDtls).map((keyName, i) => {
                                    return (
                                        <div key={i} className={`TransactionDtls_cards_table_border ${(i === Object.keys(transactionDtls).length - 1) ? 'TransactionDtls_cards_hideBottomBorder' : ""}`}>
                                            <div
                                                className={`TransactionDtls_cards_table TransactionDtls_cards_table_dtl 
                                                `}>
                                                <div className="TransactionDtls_cards_header" data-e2e={keyName}>
                                                    {keyName}
                                                </div>
                                                <div className="TransactionDtls_cards_value">
                                                    {keyName === "Status.Message" ? (transactionDtls[keyName] && transactionDtls[keyName] !== "" ? <div className="expanderBtn"><i onClick={() => {
                                                        toggleMsgExpanded(!msgExpanded);
                                                    }} className={`fa fa-${msgExpanded ? "minus" : "plus"}`} /></div> : "N/A")
                                                        :keyName === 'Status' ? (data.status && data.status.code ? getStatusComponent(data.status.code) : "N/A"):
                                                            (keyName === "To Address" || keyName === "From Address" ?
                                                                (transactionDtls[keyName] ? 
                                                                        (
                                                                           <div className="cursor-pointer">
                                                                               <span  className="cursor-pointer">
                                                                                    <span>{transactionDtls[keyName].substring(0,10)+"..." }</span>
                                                                                    <i  onClick={()=>{
                                                                                        toggleDropdown(!showDropdown)
                                                                                    }}className={`fa fa-angle-${showDropdown ? "up" : "down"} FromAddressDrop`} data-e2e="dropDown"/>
                                                                                </span>
                                                                               {showDropdown && <div className="FromAddressDropdownContent" 
                                                                               >{transactionDtls[keyName]}</div>}
                                                                           </div>
                                                                        )
                                                                    : "N/A")
                                                                : transactionDtls[keyName]
                                                            )
                                                    }
                                                </div>
                                            </div>
                                            {keyName === "Status.Message" && msgExpanded ? <div className="statusMessageValue">{transactionDtls[keyName]}</div> : null}
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    )
                }
                {activeTab === 2 &&
                    activeTab2Card.map((obj, i) =>
                        <div key={i} className="TransactionDtls_cards">
                            <div className={`TransactionDtls_cards_NoScroll TransactionDtls_cards_Scroll`}>
                                {eventsLog && eventsLog.length > 0 ? eventsLog.map((obj, i) => {
                                    if (allowedStatusList.includes(obj.code)) {
                                        return (
                                            <div key={i} className="TransactionDtls_cards_table TransactionDtls_cardsElog_table eventlog">
                                                <div className="TransactionDtls_cardsElog_header">
                                                    {moment(new Date(obj.updated)).format("DD/MM/YYYY - HH:mm")}
                                                </div>
                                                <div className="TransactionDtls_cardsELog_value">
                                                    {obj.code}
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                                    : <div className="No_Logs">No Logs</div>
                                }
                            </div>
                        </div>
                    )
                }
            </div></MediaQuery>
            <MediaQuery minWidth={481}><div className="TransactionDtls_container">
                {
                    cards.map((obj, i) =>
                        <div key={i} className="TransactionDtls_cards">
                            <div className={`TransactionDtls_cards_NoScroll TransactionDtls_cards_Scroll`}>
                                <div className="TransactionDtls_cards_heading">{obj.heading}</div>
                                {
                                    obj.heading === 'Details'
                                        ? Object.keys(transactionDtls).map((keyName, i) => {
                                            return (
                                                <div key={i} className={`TransactionDtls_cards_table_border ${(i === Object.keys(transactionDtls).length - 1) ? 'TransactionDtls_cards_hideBottomBorder' : ""}`}>
                                                    <div
                                                        className={`TransactionDtls_cards_table TransactionDtls_cards_table_dtl 
                                                `}>
                                                        <div className="TransactionDtls_cards_header" data-e2e={keyName}>
                                                            {keyName}
                                                        </div>
                                                        <div className="TransactionDtls_cards_value APIKeysTable_item">
                                                            {keyName === "Status.Message" ? (transactionDtls[keyName] && transactionDtls[keyName] !== "" ? <div className="expanderBtn"><i onClick={() => {
                                                                toggleMsgExpanded(!msgExpanded);
                                                            }} className={`fa fa-${msgExpanded ? "minus" : "plus"}`} /></div> : "N/A")
                                                                : keyName === 'Status' ? (data.status && data.status.code ? getStatusComponent(data.status.code) : "N/A") :
                                                                    (keyName === "To Address" || keyName === "From Address" ?
                                                                        (transactionDtls[keyName] && transactionDtls[keyName] != "-"?
                                                                        (
                                                                            <div className="cursor-pointer">
                                                                               <span  className="cursor-pointer">
                                                                                    <span>{transactionDtls[keyName].substring(0,10)+"..." }</span>
                                                                                    <i  onClick={()=>{
                                                                                        toggleDropdown(!showDropdown)
                                                                                    }}className={`fa fa-angle-${showDropdown ? "up" : "down"} FromAddressDrop`} data-e2e="dropDown"/>
                                                                                </span>
                                                                               {showDropdown && <div className="FromAddressDropdownContent" 
                                                                               >{transactionDtls[keyName]}</div>}
                                                                           </div>
                                                                        )
                                                                            : "N/A")                                                                           
                                                                        : transactionDtls[keyName]
                                                                    )
                                                            }
                                                        </div>                                                     
                                                    </div>
                                                    {keyName === "Status.Message" && msgExpanded ? <div className="statusMessageValue">{transactionDtls[keyName]}</div> : null}
                                                </div>
                                            )
                                        })
                                        : eventsLog && eventsLog.length > 0 ? eventsLog.map((obj, i) => {
                                            if (allowedStatusList.includes(obj.code)) {
                                                return (
                                                    <div key={i} className="TransactionDtls_cards_table TransactionDtls_cardsElog_table eventlog">
                                                        <div className="TransactionDtls_cardsElog_header">
                                                            {moment(new Date(obj.updated)).format("DD/MM/YYYY - HH:mm")}
                                                        </div>
                                                        <div className="TransactionDtls_cardsELog_value">
                                                            {obj.code}
                                                        </div>
                                                    </div>
                                                )
                                                } else {
                                                    return null;
                                                }
                                        })
                                            : <div className="No_Logs">No Logs</div>
                                }
                            </div>
                        </div>
                    )
                }
            </div></MediaQuery>
            {<Modal show={ viewPermissionOnly ? false : showApprovalModal } className="ClearAlertModal approvalModals"
                onHide={() => { setApprovalModalFlag(false) }}>
                <Modal.Body className="ClearAlertModalBody">
                    <style>{`\
                        body {
                            width: 100vw;
                        }
                    `}</style>
                    <div className="ClearAlertModalIconWrap">
                        <img src={`${image_base_url}${approveIcon}`} alt="resetIcon" className="approvalIcon" />
                    </div>
                    <div className="ClearAlertModalTitle">Approve Transaction</div>
                    <div className="ClearAlertModalDescription">Are you sure you want to approve this transaction? This action cannot be undone.</div>
                    <textarea className="commentBox commentBoxApprove" name="approveComment"
                        value={approveComment}
                        placeholder="Add note..."
                        data-e2e="text-box"
                        onChange={(e) => { setApproveComment(e.target.value) }} rows="5" cols="40" />
                    <div data-e2e="approve-btn" className={`ClearAlertModalClearBtn approveTransactionButton ${approveComment && approveComment.trim() !== "" ? "" : "approveTransactionButtonDisable"}`} onClick = { viewPermissionOnly ? () => {} : () => {
                        if (approveComment && approveComment.trim() !== "") {
                            setApprovalModalFlag(false)
                            props.approveTransaction({
                                transactionId: data.transactionId,
                                approveComment
                            });
                        } else {
                            // set error
                        }
                    }}>Approve</div>
                    <div className="ClearAlertModalGoBackBtn" onClick={ viewPermissionOnly ? () => {} : () => { setApprovalModalFlag(false) }} data-e2e="go-back-btn">Go Back</div>
                </Modal.Body>
            </Modal>}
            {<Modal show = { viewPermissionOnly ? false : showRejectModal } className="ClearAlertModal approvalModals"
                onHide={() => { setRejectModalFlag(false) }}>
                <Modal.Body className="ClearAlertModalBody">
                    <style>{`\
                        body {
                            width: 100vw;
                        }
                    `}</style>
                    <div className="ClearAlertModalIconWrap">
                        <img src={`${image_base_url}${rejectIcon}`} alt="resetIcon" className="approvalIcon" />
                    </div>
                    <div className="ClearAlertModalTitle">Reject Transaction</div>
                    <div className="ClearAlertModalDescription">Are you sure you want to reject this transaction? This action cannot be undone.</div>
                    <textarea className="commentBox commentBoxApprove commentBoxReject" name="approveComment"
                        value={rejectComment}
                        placeholder="Add note..."
                        data-e2e="text-box"
                        onChange={(e) => { setRejectComment(e.target.value) }} rows="5" cols="40" />
                    <div data-e2e="reject-btn" className={`ClearAlertModalClearBtn rejectTransactionButton ${rejectComment && rejectComment.trim() !== "" ? "" : "rejectTransactionButtonDisable"}`} onClick={ viewPermissionOnly ? () => {} : () => {
                        if (rejectComment && rejectComment.trim() !== "") {
                            setRejectModalFlag(false)
                            props.rejectTransaction({
                                transactionId: data.transactionId,
                                rejectComment
                            });
                        } else {
                            // set error
                        }
                    }}>Reject</div>
                    <div className="ClearAlertModalGoBackBtn" onClick = { viewPermissionOnly ? () => {} : () => { setRejectModalFlag(false) }} data-e2e="go-back">Go Back</div>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default TransactionDetails;

