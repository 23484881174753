const { default: localDb } = require('./localDb');

function setTheme(themeName) {
  sessionStorage.setItem("colorMode", themeName);
  document.documentElement.className = themeName;
}

function setB2CTheme(themeName) {
  sessionStorage.setItem("b2cColorMode", themeName);
  document.documentElement.className = themeName;
}

function keepTheme() {
  if (localDb.getSSVal("colorMode")) {
    if (localDb.getSSVal("colorMode") === 'dark') {
      setTheme('dark');
    } else if (localDb.getSSVal("colorMode") === 'light') {
      setTheme('light');
    }
  } else if (localDb.getSSVal("defaultTheme")) {
    if (localDb.getSSVal("defaultTheme") === "Dark Theme") {
      setTheme('dark');
    } else if (localDb.getSSVal("defaultTheme") === "Light Theme") {
      setTheme('light');
    }
  } else {
    document.documentElement.className = "dark";
    // setTheme('default');
  }
  if ([
    "/home-b2c",
    "/paysu-home-b2c",
    "/kalonpay-home-merchant",
    "/faq-b2c-home",
    "/b2cUserForgotPassword",
    "/user-wallet-home",
    "/user-settings-b2c",
    "/user-transaction-history-b2c",
  ].includes(window.location.pathname)) {
    if (localDb.getSSVal("b2cColorMode")) {
      if (localDb.getSSVal("b2cColorMode") === 'dark') {
        setB2CTheme('dark');
      } else if (localDb.getSSVal("b2cColorMode") === 'light') {
        setB2CTheme('light');
      }
    } else if (localDb.getSSVal("defaultB2CTheme")) {
      if (localDb.getSSVal("defaultB2CTheme") === "Dark Theme") {
        setB2CTheme('dark');
      } else if (localDb.getSSVal("defaultB2CTheme") === "Light Theme") {
        setB2CTheme('light');
      }
    } else {
      setB2CTheme('default');
    }
  }
}

const getHeaderButtonStyle = (colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour) => {
  let headerButtonStyle = {}
  let root = document.getElementById("root");
  if (colorMode === "dark") {
    const bannerTextDark = sessionStorage.getItem('bannerTextDark')
    if (bannerTextDark && bannerTextDark !== "null") {
      root.style.setProperty('--banner-text-color', bannerTextDark);
    }
    if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
      headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
      headerButtonStyle.borderColor = headerButtonDarkBgColour;
    }
    if (headerDarkTextColour && headerDarkTextColour !== "") {
      headerButtonStyle.color = headerDarkTextColour;
    }
  } else {
    const bannerText = sessionStorage.getItem('bannerText')
    if (bannerText && bannerText !== "null") {
      root.style.setProperty('--banner-text-color', bannerText);
    }
    if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
      headerButtonStyle.backgroundColor = headerButtonLightBgColour;
      headerButtonStyle.borderColor = headerButtonLightBgColour;
    }
    if (headerLightTextColour && headerLightTextColour !== "") {
      headerButtonStyle.color = headerLightTextColour;
    }
  }
  return headerButtonStyle;
}

const getHeaderButtonBorderStyle = (colorMode, headerButtonDarkBgColour, headerButtonLightBgColour) => {
  let headerButtonBorderStyle = {}
  if (colorMode === "dark") {
    if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
      headerButtonBorderStyle.borderColor = headerButtonDarkBgColour;
      headerButtonBorderStyle.color = headerButtonDarkBgColour;
    }
  } else {
    if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
      headerButtonBorderStyle.borderColor = headerButtonLightBgColour;
      headerButtonBorderStyle.color = headerButtonLightBgColour;
    }
  }
  return headerButtonBorderStyle;
}

const checkValueInLS = (value) => {
  return (localStorage.getItem(value) && localStorage.getItem(value) !== "" && localStorage.getItem(value) !== "null" ? true : false)
}

const checkValueInSS = (value) => {
  return (sessionStorage.getItem(value) && sessionStorage.getItem(value) !== "" && sessionStorage.getItem(value) !== "null" ? true : false)
}

module.exports = {
  setTheme,
  setB2CTheme,
  keepTheme,
  getHeaderButtonStyle,
  getHeaderButtonBorderStyle,
  checkValueInLS,
  checkValueInSS,
};
