import React from 'react';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'
import DataTable from 'react-data-table-component';

const customStyles = {
	headCells: {
		style: {
			fontSize: "12px",
			color: "#666666",			
			letterSpacing: "1px",
			whiteSpace: "nowrap"
		},
	},
	cells: {
		style: {
			fontSize: "14px",
		}
	}
};
const formatSubstring = (str, index, lengthLimit) => {
  if (!str) return 'N/A';
  const res = `${str.substring(0, index)}...${str.substring(str.length - index)}`;
  if (lengthLimit && str.length > lengthLimit) return res;
  if (!lengthLimit) return res;
  return str;
};
const TransactionTable = (props) => {
	const sortingValues = {
		"Transaction ID": "transactionId",
		"merchantTransactionId": "merchantTransactionId",
		"Type": "type",
		"User ID": "merchantUserId",
		"Date & Time": "dateTime",
		"Fiat Amount": "depositAmount",
		"Crypto Amount": "selected.amount",
		"Fee": "transactionFee",
		"Status": "status.code"
	}
	
	const onChangePage = (page) => {
		props.setPage(page)
	}
	
	const customSort = (column, sortDirection) => {
		props.changeOrder(sortingValues[column.name.props.children])
	};
	
	const columns = [
		{
			name: <p data-e2e="TransactionSort">{Object.keys(sortingValues)[0]}</p>,
			selector: row => row.transactionId || "N/A",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents" data-tip data-for={row.transactionId}>
				{row.transactionId ? row.transactionId.substring(0, 12) : "N/A"}
			</div>,
		},
		{
			name: <p data-e2e="merchantTransactionId">{Object.keys(sortingValues)[1]}</p>,
			selector: row => row?.merchantTransactionId || "N/A",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents" data-tip data-for={row?.merchantTransactionId}>
				{row?.merchantTransactionId ? formatSubstring(row?.merchantTransactionId, 4, 12) : "N/A"}
			</div>,
		},
		{
			name: <p data-e2e="TypeSort">{Object.keys(sortingValues)[1]}</p>,
			selector: row => row.type,
			sortable: true,
			cell: row => <div data-tag="allowRowEvents" className="transform-capitalize">{row.type}</div>
		},
		{
			name: <p data-e2e="userId">{Object.keys(sortingValues)[2]}</p>,
			selector: row => row.merchantUserId || "N/A",
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">{row.merchantUserId || "N/A"}</div>
		},
		{
			name: <p data-e2e="dateTime">{Object.keys(sortingValues)[3]}</p>,
			selector: row => row.status && row.status.updated || "N/A",
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">
				{row.status && row.status.updated ? moment(row.status.updated).format("DD/MM/YYYY - HH:mm") : "N/A" }
			</div>
		},
		{
			name: <p data-e2e="FiatAmountSort">{Object.keys(sortingValues)[4]}</p>,
			selector: row => row.depositAmount,
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">
				{getSymbolFromCurrency(row.depositFiatCurrency) + parseFloat(row.depositAmount).toFixed(6)}
			</div>
		},
		{
			name: <p data-e2e="CryptoAmountSort">{Object.keys(sortingValues)[5]}</p>,
			selector: row => row.selected ? row.selected.amount : 0,
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">
				{row.selected && row.selected.cryptoId ? 
					`${row.selected.cryptoId} - ${(row.type && row.type.toLowerCase() === "withdrawal" 
						? `${(row.selected.amount ? row.selected.amount.toString().substring(0, 11) : "N/A")}` 
						: (row.selected.amount ? row.selected.amount.toString().substring(0, 11) : "N/A"))}` 
					: "N/A"
				}
			</div>
		},
		{
			name: <p data-e2e="feeSort">{Object.keys(sortingValues)[6]}</p>,
			selector: row => row.transactionFee || "-",
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">
				{row.transactionFee ? parseFloat(row.transactionFee).toFixed(6) : "-"}
			</div>
		},
		{
			name: <p data-e2e="statusSort">{Object.keys(sortingValues)[7]}</p>,
			selector: row => row.status && row.status.code || "N/A",
			sortable: true,
			cell: (row, index) => <div  className="transactionsTable_item" style={{display: "flex", alignItems: "center"}}>
					{row.status && row.status.code ? getStatusComponent(row.status.code, index) : "N/A"}
			</div>
		},
	];
    
	const getStatusComponent = (status, index) => {
		switch (status) {
			case "CreatePayment":
			case "DepositFlow:Launched":
			case "DepositFlow:AwaitingDeposit":
			case "DepositMonitor:MonitoringMemPool":
			case "DepositMonitor:DepositPendingManualApproval":
			case "CreateSecurePayment":
                return <div data-e2e="pending" className="Tstatus_witheld">PENDING</div>;
            case "DepositMonitor:TimedOutMonitoringMemPool":
                return <div data-e2e="timeOut" className="Tstatus_witheld">TIMED OUT</div>;
            case "DepositMonitor:DepositManuallyCompleted":
            case "DepositMonitor:DepositCompleted":
            case "MerchantWallet:BalanceUpdated":
            case "WalletManagementService:WithdrawalCompleted":
            case "WalletManagementService:TransferCompleted":
            case "DepositFlowViaWallet:DepositCompleted":
			case "DepositMonitor:DepositRefunded":
				return <div data-e2e="success" className="Tstatus_success">SUCCESS</div>;
			case "DepositMonitor:DepositRejected":
			case "DepositMonitor:DepositFailed":
			case "DepositMonitor:RefundFailed":
			case "WalletManagementService:WithdrawalRejectionCompleted":
			case "WalletManagementService:WithdrawalError":
			case "WalletManagementService:WalletTransferError":
			case "WithdrawalFlow:Rejected":
                return <div className="Tstatus_failed" data-e2e="rejected">REJECTED</div>;
            case "DepositMonitor:DepositPendingManualApproval":
            case "WithdrawalFlow:PendingApproval":
                return <div data-e2e={`${index}-require-approval`} className="Tstatus_witheld">REQUIRE APPROVAL</div>;
            default:
                return <div data-e2e="processing" className="Tstatus_pending">PROCESSING</div>;
        }
    }

	return (
		<div className="TransactionTableMain">
			<DataTable
        onChangePage={onChangePage}
				paginationTotalRows={props.totalCount}
				paginationPerPage={10}
				paginationComponentOptions={{
					noRowsPerPage: true
				}}
				paginationServer
				progressPending={props.isLoadingTransactions}
				pagination
				columns={columns}
				data={props.data}
				customStyles={customStyles}
				onSort={customSort}
				sortServer={true}
				highlightOnHover={true}
				pointerOnHover={true}
				noDataComponent={<div style={{ padding: '24px', fontSize: "14px" }}>There are no data to display</div>}
				onRowClicked={(row, e) => props.openTransactionDetails(row)}
			/>
		</div>
	)
}

export default TransactionTable;